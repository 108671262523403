import React from 'react'
import {
  BankFilled,
  CarryOutFilled,
  BookFilled,
  ContactsFilled,
  SettingFilled,
  ApiOutlined,
  AreaChartOutlined,
  ContainerOutlined,
  ShoppingCartOutlined,
  FundOutlined,
} from '@ant-design/icons'
import config from 'configs/config'

const menuItems = {
  items: [
    {
      id: 'sidebar-title-items',
      title: '',
      // title: 'Pages',
      type: 'group',
      children: [
        {
          id: 'sidebar-title-auction',
          title: 'Auctions',
          type: 'item',
          icon: (isSelected, color) => (
            <ShoppingCartOutlined
              className="icon "
              style={{
                background: isSelected ? color : '',
              }}
            />
          ),
          url: '/auction',
          roles: [config.roles.subscriber],
        },
        {
          id: 'sidebar-title-campaigns',
          title: 'Campaigns',
          type: 'item',
          icon: (isSelected, color) => (
            <ApiOutlined
              className="icon "
              style={{
                background: isSelected ? color : '',
              }}
            />
          ),
          url: '/campaigns',
          roles: [config.roles.subscriber],
        },
        {
          id: 'sidebar-title-events',
          title: 'Events',
          type: 'item',
          icon: (isSelected, color) => (
            <CarryOutFilled
              className="icon "
              style={{
                background: isSelected ? color : '',
              }}
            />
          ),
          url: '/events',
          roles: [config.roles.subscriber],
        },
        {
          id: 'sidebar-title-fundraisers',
          title: 'Fundraisers',
          type: 'item',
          icon: (isSelected, color) => (
            <FundOutlined
              className="icon "
              style={{
                background: isSelected ? color : '',
              }}
            />
          ),
          url: '/fundraisers',
          roles: [
            config.roles.admin,
            config.roles.donor,
            config.roles.presenter,
            config.roles.subscriber,
          ],
        },
        {
          id: 'sidebar-title-businesses',
          title: 'Marketplace',
          type: 'item',
          icon: (isSelected, color) => (
            <BankFilled
              className="icon"
              style={{
                background: isSelected ? color : '',
              }}
            />
          ),
          url: '/businesses',
          roles: [config.roles.subscriber],
        },
        {
          id: 'sidebar-title-programs',
          title: 'Programs',
          type: 'item',
          icon: (isSelected, color) => (
            <BookFilled
              className="icon "
              style={{
                background: isSelected ? color : '',
              }}
            />
          ),
          url: '/programs',
          roles: [config.roles.subscriber],
        },
        {
          id: 'sidebar-title-room',
          title: 'Rentals',
          type: 'item',
          icon: (isSelected, color) => (
            <ContainerOutlined
              className="icon "
              style={{
                background: isSelected ? color : '',
              }}
            />
          ),
          url: '/rentals',
          roles: [config.roles.subscriber],
        },
        {
          id: 'sidebar-title-services',
          title: 'Services',
          type: 'item',
          icon: (isSelected, color) => (
            <AreaChartOutlined
              className="icon "
              style={{
                background: isSelected ? color : '',
              }}
            />
          ),
          url: '/services',
          roles: [config.roles.subscriber],
        },
        {
          id: 'sidebar-title-volunteer',
          title: 'Volunteer Opps',
          type: 'item',
          icon: (isSelected, color) => (
            <ContactsFilled
              className="icon "
              style={{
                background: isSelected ? color : '',
              }}
            />
          ),
          url: '/volunteer',
          roles: [config.roles.subscriber],
        },
        // {
        //   id: 'sidebar-title-report',
        //   title: 'Reports',
        //   type: 'item',
        //   icon: (isSelected, color) => (
        //     <BarChartOutlined
        //       className="icon "
        //       style={{
        //         background: isSelected ? color : '',
        //       }}
        //     />
        //   ),
        //   url: '/reports',
        //   roles: [
        //     // config.roles.admin,
        //     // config.roles.donor,
        //     config.roles.subscriber,
        //   ],
        // },
      ],
    },
    {
      id: 'sidebar-title-groups',
      title: 'Groups',
      className: 'd-none',
      type: 'group',
      children: [
        {
          id: 'admin',
          title: <span className="label">Admin</span>,
          type: 'collapse',
          icon: (isSelected, color) => (
            <SettingFilled
              className="icon"
              style={{
                background: isSelected ? color : '',
              }}
            />
          ),
          children: [
            {
              id: 'sidebar-title-organizations',
              title: 'Organizations',
              type: 'item',
              url: '/organizations',
              roles: [config.roles.admin],
            },
            {
              id: 'sidebar-title-hardware',
              title: 'Hardware',
              type: 'item',
              url: '/hardware',
              roles: [config.roles.admin],
            },
            {
              id: 'sidebar-subtitle-manage-gov-issued-docs',
              title: 'Manage Gov Docs',
              type: 'item',
              url: '/settings/manage-gov-issued-docs',
              roles: [config.roles.admin],
            },
            {
              id: 'sidebar-subtitle-manage-feedbacks',
              title: 'Community Feedback',
              type: 'item',
              url: '/settings/manage-feedbacks',
              roles: [config.roles.admin],
            },
            {
              id: 'sidebar-subtitle-manage-rental-facilities',
              title: 'Rental Request',
              type: 'item',
              url: '/settings/manage-reservations-requests',
              roles: [config.roles.admin],
            },
          ],
          roles: [config.roles.admin],
        },
        {
          id: 'Organization',
          title: <span className="label">My Organization</span>,
          type: 'collapse',
          icon: (isSelected, color) => (
            <SettingFilled
              className="icon"
              style={{
                background: isSelected ? color : '',
              }}
            />
          ),
          children: [
            {
              id: 'sidebar-subtitle-organization-home',
              title: 'Dashboard',
              type: 'item',
              url: '/home',
              roles: [config.roles.subscriber],
            },
            {
              id: 'sidebar-subtitle-organization-new-detail',
              title: 'New Org Details',
              type: 'item',
              url: '/settings/manage-organization/organization-detail',
              roles: [config.roles.subscriber],
            },
            {
              id: 'sidebar-subtitle-organization-detail',
              title: 'Legacy Settings',
              type: 'item',
              url: '/settings/register-organization',
              roles: [config.roles.subscriber],
            },
            {
              id: 'sidebar-subtitle-manage-payments-accounts',
              title: 'Payments',
              type: 'item',
              url: '/settings/manage-payments-accounts',
              roles: [config.roles.subscriber],
            },
            {
              id: 'sidebar-subtitle-manage-report',
              title: 'Reports',
              type: 'item',
              url: '/reports',
              roles: [config.roles.subscriber],
            },
            {
              id: 'sidebar-subtitle-manage-association-requests',
              title: 'Business Requests',
              type: 'item',
              url: '/settings/manage-organization/listing-requests',
              roles: [config.roles.subscriber],
            },
            {
              id: 'sidebar-subtitle-manage-businesses-configuration',
              title: 'B2B Program',
              type: 'item',
              url: '/settings/manage-organization/my-businesses-configuration',
              roles: [config.roles.subscriber],
            },
            {
              id: 'sidebar-subtitle-manage-businesses-configuration',
              title: 'B2B Program (New)',
              type: 'item',
              url: '/settings/manage-organization/my-businesses-configurationv2',
              roles: [config.roles.subscriber],
            },
            {
              id: 'sidebar-subtitle-manage-my-community',
              title: 'Community',
              type: 'item',
              url: '/settings/manage-organization/my-community',
              roles: [config.roles.subscriber],
            },
            // {
            //   id: 'sidebar-subtitle-manage-my-reports',
            //   title: 'My Reports',
            //   type: 'item',
            //   url: '/settings/manage-organization/my-reports',
            // },
            // {
            //   id: 'sidebar-subtitle-manage-my-events',
            //   title: 'Event Presenters',
            //   type: 'item',
            //   url: '/settings/manage-organization/my-events',
            //   roles: [config.roles.subscriber],
            // },
          ],
          roles: [config.roles.subscriber],
        },
        {
          id: 'account',
          title: <span className="label">My Stuff</span>,
          type: 'collapse',
          icon: (isSelected, color) => (
            <SettingFilled
              className="icon"
              style={{
                background: isSelected ? color : '',
              }}
            />
          ),
          children: [
            {
              id: 'sidebar-subtitle-register-organization',
              title: 'Register Organization',
              type: 'item',
              url: '/settings/register-organization',
              roles: [config.roles.donor, config.roles.subscriber],
            },
            {
              id: 'sidebar-subtitle-my-account',
              title: 'My Account',
              type: 'item',
              url: '/settings/account',
              roles: [config.roles.donor],
            },
            {
              id: 'sidebar-subtitle-my-invites',
              title: 'Requests',
              type: 'item',
              url: '/settings/invites',
              roles: [config.roles.donor],
            },
            {
              id: 'sidebar-subtitle-manage-business',
              title: 'My Business',
              type: 'collapse',
              roles: [config.roles.business],
              children: [
                {
                  id: 'sidebar-subtitle-business-detail',
                  title: 'Business Detail',
                  type: 'item',
                  url: '/settings/manage-organization/business-detail',
                },
                {
                  id: 'sidebar-subtitle-my-business-service-requests',
                  title: 'Service Requests',
                  type: 'item',
                  url: '/settings/manage-organization/my-business-service-requests',
                },
                {
                  id: 'sidebar-subtitle-my-business-customers',
                  title: 'My Customers',
                  type: 'item',
                  url: '/settings/manage-organization/manage-business-customers',
                },
                {
                  id: 'sidebar-subtitle-my-business-earnings',
                  title: 'My Revenue',
                  type: 'item',
                  url: '/settings/manage-organization/manage-business-earnings',
                },
                {
                  id: 'sidebar-subtitle-my-business-manage-payments-accounts',
                  title: 'Business payments',
                  type: 'item',
                  url: '/settings/manage-organization/manage-payments-accounts',
                },
              ],
            },
            {
              id: 'sidebar-subtitle-logout',
              title: 'Logout',
              type: 'item',
              button: true,
              url: '/',
              roles: [
                config.roles.admin,
                config.roles.donor,
                config.roles.presenter,
                config.roles.subscriber,
              ],
            },
          ],
          roles: [config.roles.donor],
        },
      ],
    },
  ],
}

export { menuItems }
