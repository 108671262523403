import React, { useEffect, useState } from 'react'
import { Card } from 'antd'
import { PageNotFound } from 'screens/main/settings/page-not-found'
import { ComponentViewHeader } from 'components/common/componentView/components'
import { useRoles } from 'hooks'
import { MyBusinessEntities } from './MyBusinessEntities'
import { OrganizationSubscriptionProgram } from '../MyBusinessesConfiguration/OrganizationSubscriptionProgram'

const MyBusinessesConfigurationNew = () => {
  const [section, setSection] = useState()
  const { isSubscriber, isDonor } = useRoles()

  const handleSection = (key) => {
    setSection(key)
  }

  useEffect(() => {
    if (isSubscriber) {
      setSection(1)
    } else if (isDonor) {
      setSection(2)
    } else {
      setSection(1)
    }
  }, [isSubscriber, isDonor])

  return (
    <>
      {isSubscriber ? (
        <Card className="header-solid" bordered={false}>
          <div className="t-flex t-flex-wrap t-justify-between">
            <ComponentViewHeader heading="B2B Marketplace" />
          </div>
          <div className="t-flex t-flex-row md:t-justify-start t-justify-center t-border-b-2 t-border-b-gray-100">
            <div
              onClick={() => handleSection(1)}
              className={`${
                section === 1 ? 't-border-b-[#2e97de] t-border-b-2' : ''
              } t-pt-6 t-cursor-pointer t-w-[20%] t-bg-white`}
            >
              <h1
                className={`${
                  section === 1 ? 't-text-[#2e97de]' : 't-text-black'
                } t-text-sm t-font-bold t-text-center roboto-font t-mb-3`}
              >
                My Business Entities List
              </h1>
            </div>
            <div
              onClick={() => handleSection(2)}
              className={`${
                section === 2 ? 't-border-b-[#2e97de] t-border-b-2' : ''
              } t-pt-6 t-cursor-pointer t-w-[20%] t-bg-white`}
            >
              <h1
                className={`${
                  section === 2 ? 't-text-[#2e97de]' : 't-text-black'
                } t-text-sm t-font-bold t-text-center roboto-font t-mb-3`}
              >
                My Subscription Program
              </h1>
            </div>
          </div>

          {section === 1 && <MyBusinessEntities />}
          {section === 2 && <OrganizationSubscriptionProgram />}
        </Card>
      ) : (
        <PageNotFound />
      )}
    </>
  )
}

export { MyBusinessesConfigurationNew }
