import React, { useEffect, useRef, useState } from 'react'
import {
  message,
  Skeleton,
  Card,
  Row,
  Col,
  Typography,
  Divider,
  Button,
  Dropdown,
  Menu,
  Carousel,
  Switch,
  Tooltip,
  Tag,
} from 'antd'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { GiFinishLine } from 'react-icons/gi'
import { awsconfig, config } from 'configs'
import ReactPlayer from 'react-player'
import QRCode from 'qrcode.react'
import { formatAmount, getWebAppFundraiserUrl } from 'utils'
import { useDonations } from 'screens/main/fundraiser/components/useDonations'
import {
  BsFillArrowLeftCircleFill,
  BsFillArrowRightCircleFill,
  BsThreeDotsVertical,
} from 'react-icons/bs'
import { ShareModal } from 'components'
import { IoIosArrowBack } from 'react-icons/io'
import sample from 'assets/images/sample/fundraiser-default-image.png'
import { RiAuctionLine, RiVideoLine } from 'react-icons/ri'
import { SoldItems } from 'components/common/auction'
import { useFundraiserSelector } from 'store/fundraiserSlice/fundraiserReducer'
import {
  getFundraiserAuctionItems,
  getFundraiserControlScreenDetails,
  getFundraiserDetails,
  getSubscriberMarkupInfo,
  getSubscriberPaymentInformation,
  updateFundraiserAuctionItemsView,
} from 'store/fundraiserSlice/fundraiserActions'
import {
  CheckEntry,
  Control,
  Donations,
  PendingCash,
  SeedDonations,
  AddCollectedAmount,
  AuctionItems,
  DecidingItems,
  RegisterCashDonation,
  ManualPledges,
  BulkImportDonations,
} from './components'
import { SendRemindersModal } from './components/SendReminders'

const { Title, Paragraph } = Typography

const FundraiserControl = () => {
  const slider = useRef(null)
  const [activeKey, setActiveKey] = useState('1')
  const [shareModal, setShareModal] = useState(false)
  const [reminderModal, setReminderModal] = useState(false)
  const [auctionItemsLoading, setAuctionItemsLoading] = useState(true)
  const [auctionItemsView, setAuctionItemsView] = useState(false)
  const [decidingModal, setDecidingModal] = useState(false)
  const [switchLoading, setSwitchLoading] = useState(false)
  const { id } = useParams()
  const { loading, fundraiser, fundraiserDetail, fundraiserAuctionItems } =
    useFundraiserSelector()
  const history = useHistory()
  const { state, search } = useLocation()
  const dispatch = useDispatch()

  const filterFundraiserAuctionItems = fundraiserAuctionItems?.filter(
    (item) => item.status === 'Live',
  )

  useDonations(id)

  useEffect(() => {
    if (search) {
      const urlSearchParams = new URLSearchParams(search)
      const { key } = Object.fromEntries(urlSearchParams.entries())
      if (key) setActiveKey(key)
    }
    // eslint-disable-next-line
  }, [activeKey])

  useEffect(() => {
    if (fundraiser) {
      const view = fundraiser.auctionItemsView
      setAuctionItemsView(view || false)
    }
    dispatch(getSubscriberPaymentInformation(fundraiser?.organizationId))
    dispatch(getSubscriberMarkupInfo(fundraiser?.organizationId))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fundraiser])

  useEffect(() => {
    if (id) {
      dispatch(getFundraiserDetails(id))
      dispatch(getFundraiserControlScreenDetails(id))
    } else {
      message.error('Invalid URL')
      history.push('/fundraisers')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const fetchAuctionItems = () => {
    if (id) {
      dispatch(getFundraiserAuctionItems(id, setAuctionItemsLoading))
    }
  }

  useEffect(() => {
    fetchAuctionItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const copyToClipboard = () => {
    const url = getWebAppFundraiserUrl(
      fundraiser.iD,
      fundraiser?.organization?.domain,
    )
    navigator.clipboard.writeText(url)
    message.success('Copied to Clipboard')
  }

  const customMenu = () => (
    <Menu>
      {/* <Menu.Item
        key={1}
        onClick={() => (fundraiser?.startFundraiser ? stopEvent() : startFundraiser())}
      >
        {fundraiser?.startFundraiser ? 'Stop Event' : 'Start Event'}
      </Menu.Item>
      {!isFundraisingStopped && (
        <Menu.Item
          key={2}
          onClick={() =>
            isFundraisingNotStarted ? startFundraising() : stopFundraising()
          }
        >
          {isFundraisingNotStarted ? 'Start Fundraising' : 'Stop Fundraising'}
        </Menu.Item>
      )} */}
      <Menu.Item key={3} onClick={() => {}}>
        Update Youtube Link
      </Menu.Item>
      <Menu.Item key={4} onClick={() => setReminderModal(true)}>
        Send Pledge Reminders
      </Menu.Item>
      <Menu.Item key={5} onClick={() => setShareModal(true)}>
        Share Fundraiser
      </Menu.Item>
      <Menu.Item key={6} onClick={() => copyToClipboard()}>
        Copy URL To Embed
      </Menu.Item>
    </Menu>
  )

  const addToQueryParam = (key) => {
    const currentUrl = window.location.href
    const urlObject = new URL(currentUrl)
    const queryParams = new URLSearchParams(urlObject.search)

    if (queryParams.has('key')) {
      queryParams.set('key', key)
    } else {
      queryParams.append('key', key)
    }

    const newUrl = `${urlObject.origin}${
      urlObject.pathname
    }?${queryParams.toString()}`
    if (window.history.pushState) {
      window.history.pushState(null, null, newUrl)
    } else {
      window.location.replace(newUrl)
    }
    setActiveKey(key)
  }

  let addMenu = []
  if (fundraiser?.isPrivate) {
    addMenu = [
      {
        key: '6',
        label: (
          <div onClick={() => addToQueryParam('6')}>
            Add Cash/Check Collected
          </div>
        ),
      },
    ]
  }

  const menu = (
    <Menu
      items={[
        {
          key: '1',
          label: <div onClick={() => addToQueryParam('1')}>Control</div>,
        },
        {
          key: '2',
          label: <div onClick={() => addToQueryParam('2')}>Donations</div>,
        },
        // {
        //   key: '3',
        //   label: <div onClick={() => addToQueryParam('3')}>Check Entry</div>,
        // },
        {
          key: '4',
          label: (
            <div
              onClick={() => addToQueryParam('4')}
              id="control-screen-dropdown-cash"
            >
              Pending Cash
            </div>
          ),
        },
        {
          key: '5',
          label: (
            <div
              onClick={() => addToQueryParam('5')}
              id="control-screen-dropdown-donations"
            >
              Seed Donation
            </div>
          ),
        },
        ...addMenu,
        {
          key: '9',
          label: (
            <div onClick={() => addToQueryParam('9')}>
              Register Cash Donation
            </div>
          ),
        },
        {
          key: '10',
          label: <div onClick={() => addToQueryParam('10')}>Add Pledge</div>,
        },
        {
          key: '11',
          label: (
            <div onClick={() => addToQueryParam('11')}>
              Bulk Import Donation
            </div>
          ),
        },
        fundraiserDetail?.isAuction && {
          key: '7',
          label: <div onClick={() => addToQueryParam('7')}>Auction Items</div>,
        },
        fundraiserDetail?.isAuction && {
          key: '8',
          label: <div onClick={() => addToQueryParam('8')}>Sold Items</div>,
        },
      ]}
    />
  )

  const onEndAuction = () => {
    setDecidingModal(true)
  }

  const isFundraisingNotStarted = fundraiser?.fundraiserStatus === 'Not-Started'
  const isFundraisingStarted = fundraiser?.fundraiserStatus === 'Started'
  const isFundraisingStopped = fundraiser?.fundraiserStatus === 'Stopped'

  return (
    <Card className="t-pb-10">
      <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
        <Row className="t-relative">
          <div
            onClick={() =>
              state && state.urlData
                ? history.push({
                    pathname: state.urlData.pathname,
                    state: { key: state.urlData.activeKey },
                  })
                : history.push('/fundraisers')
            }
            className="t-rounded-full glass_bg t-cursor-pointer t-fixed t-top-20 md:t-top-24 t-z-50 t-text-secondary-100 t-p-1"
          >
            <IoIosArrowBack fontSize="1.5rem" />
          </div>
        </Row>
        <Row>
          <Col xl={18} lg={16} md={12} sm={24} xs={24} className="t-pr-2">
            <Row
              justify="space-between"
              className="t-items-center t-mb-4 t-hidden md:t-flex"
            >
              <div className="t-ml-10 t-flex t-space-x-4 t-items-center">
                <h1 className="t-capitalize t-text-3xl t-font-bold t-text-secondary-100">
                  {fundraiser?.title}
                </h1>
                {fundraiser?.startFundraiser && (
                  <div className="t-space-x-2 t-flex t-items-center t-border-2 t-px-2 t-py-1 t-border-green-400 t-rounded-full">
                    <div className="t-h-3 t-w-3 t-bg-green-400 t-rounded-full" />
                    <h1 className="t-text-secondary-100">Live</h1>
                  </div>
                )}
              </div>
            </Row>
            <Row className="t-mb-4 t-flex t-items-center t-visible md:t-hidden">
              <Row className="t-w-full t-flex t-justify-between t-items-center">
                <div className="t-flex t-space-x-4 t-items-center t-w-10/12">
                  <h1 className="t-ml-10 t-text-xl t-font-bold t-text-secondary-100">
                    {`${fundraiser?.title}`}
                  </h1>
                  {fundraiser?.startFundraiser && (
                    <div className="t-space-x-2 t-flex t-items-center t-border-2 t-px-2 t-py-1 t-border-green-400 t-rounded-full">
                      <div className="t-h-2 t-w-2 t-bg-green-400 t-rounded-full" />
                      <h1 className="t-text-secondary-100 t-text-xs">Live</h1>
                    </div>
                  )}
                </div>
                <div className="t-w-2/12 t-flex t-justify-end">
                  <Dropdown overlay={customMenu()}>
                    <BsThreeDotsVertical
                      fontSize="1.2rem"
                      className="t-text-secondary-100 t-cursor-pointer"
                    />
                  </Dropdown>
                </div>
              </Row>
            </Row>
            <Row span={24} lg={24} xl={24} className="col-gallery mb-24">
              <Skeleton
                loading={auctionItemsLoading}
                active
                paragraph={{ rows: 18 }}
              >
                {!auctionItemsLoading &&
                filterFundraiserAuctionItems?.length > 0 &&
                auctionItemsView ? (
                  <div className="t-relative t-w-full t-h-44 md:t-h-96 t-px-2">
                    <BsFillArrowLeftCircleFill
                      fontSize="1.2rem"
                      onClick={() => slider.current.prev()}
                      className="t-text-secondary-100 t-cursor-pointer t-absolute t-top-[35%] -t-left-[6px] t-hidden md:t-block"
                    />
                    <Carousel ref={slider} slidesPerRow={3}>
                      {filterFundraiserAuctionItems.map((item) => (
                        <div className="t-h-44 md:t-h-96 t-px-2">
                          {/* <div className="t-h-44 md:t-h-96 t-flex t-justify-center t-items-center"> */}
                          <Card
                            bordered
                            hoverable
                            cover={
                              <img
                                alt="example"
                                className="t-mt-2 t-w-full t-h-16 md:t-h-48 t-object-contain t-rounded-br-none t-rounded-bl-none t-rounded-tr-md t-rounded-tl-md md:t-rounded-md"
                                src={
                                  item?.itemImage?.fileName
                                    ? awsconfig.bucketUrl +
                                      item?.itemImage?.fileName
                                    : sample
                                }
                              />
                            }
                            // className="t-w-[30%]"
                          >
                            <div className="t-flex t-justify-between t-items-center">
                              <h5 className="t-text-base md:t-text-2xl t-text-secondary-100 t-font-bold t-truncate">
                                {item.title}
                              </h5>
                            </div>

                            <div className="t-flex t-justify-between">
                              <div className="card-tag t-font-thin t-text-secondary-100">
                                {item.currentBid &&
                                  formatAmount(item.currentBid)}
                              </div>
                            </div>
                            <p className="t-line-clamp-1 t-text-sm">
                              {item.description}
                            </p>
                          </Card>
                        </div>
                      ))}
                    </Carousel>
                    <BsFillArrowRightCircleFill
                      fontSize="1.2rem"
                      onClick={() => slider.current.next()}
                      className="t-text-secondary-100 t-cursor-pointer t-absolute t-top-[35%] -t-right-[6px] t-hidden md:t-block"
                    />
                  </div>
                ) : (
                  <>
                    {fundraiser?.youtubeLink &&
                    fundraiser?.youtubeLink !== '' ? (
                      <ReactPlayer
                        playing
                        controls
                        width="100%"
                        url={fundraiser?.youtubeLink}
                        className="t-h-40 md:t-h-96 mb-24"
                      />
                    ) : (
                      <img
                        src={
                          fundraiser?.flyer?.length
                            ? awsconfig.bucketUrl +
                              fundraiser?.flyer[0]?.fileName
                            : fundraiser?.organization?.backgroundImage
                            ? fundraiser?.organization?.backgroundImage
                            : sample
                        }
                        alt="card"
                        className="shadow-3 t-h-40 md:t-h-96 mb-24 t-w-full t-bg-cover t-bg-no-repeat t-object-cover"
                      />
                    )}
                  </>
                )}
                {!auctionItemsLoading && fundraiserAuctionItems?.length > 0 && (
                  <>
                    <div className="t-w-full t-flex t-justify-end t-items-center t-space-x-2">
                      <Tooltip title="End Auction">
                        <GiFinishLine
                          fontSize="1.8rem"
                          className="t-text-secondary-100 t-cursor-pointer"
                          onClick={() => onEndAuction()}
                        />
                      </Tooltip>
                      <Switch
                        loading={switchLoading}
                        checkedChildren={<RiAuctionLine />}
                        unCheckedChildren={<RiVideoLine />}
                        checked={auctionItemsView}
                        onChange={(value) =>
                          dispatch(
                            updateFundraiserAuctionItemsView(
                              fundraiser?.iD,
                              {
                                organizationId: fundraiser?.organizationId,
                                status: value ? 1 : 0,
                              },
                              setSwitchLoading,
                            ),
                          )
                        }
                      />
                    </div>
                    <DecidingItems
                      visible={decidingModal}
                      onClose={() => setDecidingModal(false)}
                    />
                  </>
                )}
              </Skeleton>
            </Row>
            <Row>
              <Title level={4}>Description</Title>
            </Row>
            <Row>
              <Paragraph className="t-capitalize">
                {fundraiser?.description}
              </Paragraph>
            </Row>
            <Row span={24} justify="space-between" className="t-pr-4">
              <Col>
                <Row>
                  <Title level={4}>Organization</Title>
                </Row>
                <Row>
                  <Paragraph className="t-capitalize">
                    {fundraiser?.organizationName}
                  </Paragraph>
                </Row>
              </Col>
              {/* <Col className="t-hidden md:t-block">
                <Row>
                  <Title level={4}>Current Ask</Title>
                </Row>
                <Row>
                  <Paragraph className="t-capitalize">
                    {fundraiser?.CurrentAsk}
                  </Paragraph>
                </Row>
              </Col> */}
              <Col>
                <Row>
                  <Title level={4}>Goal</Title>
                </Row>
                <Row>{formatAmount(fundraiser?.targetAmount)}</Row>
              </Col>
            </Row>
            {/* <Divider /> */}
            {/* <Row
              span={24}
              justify="space-between"
              className="t-hidden md:t-flex t-pr-4"
            >
              <Col>
                <Button
                  size="small"
                  type="primary"
                  className="t-w-full"
                  onClick={() => setPledgeModal(true)}
                >
                  Pledge
                </Button>
              </Col>
              <Col>
                <Button
                  size="small"
                  type="primary"
                  className="t-w-full"
                  onClick={() => setDonateModal(true)}
                >
                  Donate
                </Button>
              </Col>
            </Row> */}
            <>
              <Divider />
              {fundraiserDetail?.enableAuction && (
                <Row
                  className="t-flex t-justify-center t-items-center"
                  animated={false}
                >
                  <Tag colors="green">
                    Note: Select auction options from dropdown
                  </Tag>
                </Row>
              )}
              <Row className="t-w-full" animated={false}>
                <div className="t-w-full t-flex t-justify-between t-m-2 t-items-center">
                  <h1 className="t-font-bold t-text-secondary-100 t-text-xl">
                    {activeKey === '1' && 'Control'}
                    {activeKey === '2' && 'Donations'}
                    {activeKey === '3' && 'Check Entry'}
                    {activeKey === '4' && 'Pending Cash'}
                    {activeKey === '5' && 'Seed Donation'}
                    {activeKey === '6' && 'Add Cash/Check Collected'}
                    {activeKey === '7' && 'Auction Items'}
                    {activeKey === '8' && 'Sold Items'}
                    {activeKey === '9' && 'Register Cash Donation'}
                    {activeKey === '10' && 'Add Pledge'}
                    {activeKey === '11' && 'Bulk Import Donation'}
                  </h1>
                  <Dropdown overlay={menu} placement="bottomLeft" arrow>
                    <BsThreeDotsVertical
                      fontSize="1.5rem"
                      id="control-screen-dropdown"
                      className="t-cursor-pointer t-mx-1"
                    />
                  </Dropdown>
                </div>
              </Row>
              <Row className="t-w-full" animated={false}>
                {activeKey === '1' && (
                  <Control
                    visible={reminderModal}
                    setVisible={setReminderModal}
                    setDecidingModal={setDecidingModal}
                  />
                )}
                {activeKey === '2' && <Donations />}
                {activeKey === '3' && <CheckEntry />}
                {activeKey === '4' && <PendingCash />}
                {activeKey === '5' && <SeedDonations />}
                {activeKey === '6' && <AddCollectedAmount />}
                {activeKey === '7' && <AuctionItems />}
                {activeKey === '8' && (
                  <SoldItems type="fundraiser" parentId={id} />
                )}
                {activeKey === '9' && <RegisterCashDonation />}
                {activeKey === '10' && <ManualPledges />}
                {activeKey === '11' && <BulkImportDonations />}
              </Row>
            </>
          </Col>
          <Col
            xl={6}
            lg={8}
            md={12}
            sm={24}
            xs={24}
            className="t-bg-tertiary-100 t-p-4 t-rounded-lg"
          >
            <Row className="t-flex t-justify-center t-items-center">
              <div className="t-text-center t-my-2 t-mb-4 t-w-full">
                {isFundraisingNotStarted && (
                  <h1 className="t-p-2 t-bg-red-400 t-border-2 t-border-black t-rounded-lg t-shadow-md t-font-semibold">
                    Fundraising Not Started
                  </h1>
                )}
                {isFundraisingStarted && (
                  <h1 className="t-p-2 t-bg-green-400 t-border-2 t-border-black t-rounded-lg t-shadow-md t-font-semibold">
                    Fundraising Ongoing
                  </h1>
                )}
                {isFundraisingStopped && (
                  <h1 className="t-p-2 t-bg-blue-400 t-border-2 t-border-black t-rounded-lg t-shadow-md t-font-semibold">
                    Fundraising Stopped
                  </h1>
                )}
              </div>
              <div className="t-bg-gray-500 t-border-8 t-border-gray-800 t-rounded-full t-h-52 t-w-52 t-flex t-justify-center t-items-center t-flex-col">
                <h6 className="t-font-semibold t-text-md">Raised Total</h6>
                <h6 className="t-font-bold t-text-3xl t-text-green-500">
                  {/* eslint-disable-next-line */}
                  {fundraiserDetail?.status?.Total &&
                    formatAmount(fundraiserDetail?.status?.Total)}
                </h6>
                <h6 className="t-font-semibold t-text-md">
                  Remaining
                  <span className="t-text-yellow-500 t-font-bold">
                    {` ${
                      fundraiser?.targetAmount -
                        fundraiserDetail?.status?.Total <
                      0
                        ? formatAmount(0)
                        : formatAmount(
                            fundraiser?.targetAmount -
                              fundraiserDetail?.status?.Total,
                          )
                    }`}
                  </span>
                </h6>
              </div>
            </Row>
            <Divider />
            <Col lassName="t-my-4">
              <Row className="t-w-full t-flex t-justify-between t-items-center t-my-2">
                <div className="t-flex t-items-center t-space-x-2">
                  <div className="t-h-3 t-w-3 t-bg-green-300 t-rounded-full" />
                  <h6 className="t-font-semibold"># of Donations</h6>
                </div>
                <h6 className="t-font-semibold">
                  {fundraiserDetail?.status?.Donations}
                </h6>
              </Row>

              <Row className="t-w-full t-flex t-justify-between t-items-center t-my-2">
                <div className="t-flex t-items-center t-space-x-2">
                  <div className="t-h-3 t-w-3 t-bg-green-300 t-rounded-full" />
                  <h6 className="t-font-semibold">Total Pledges</h6>
                </div>
                <h6 className="t-font-semibold">
                  {fundraiserDetail?.status?.TotalPledges}
                </h6>
              </Row>

              <Row className="t-w-full t-flex t-justify-between t-items-center t-my-2">
                <div className="t-flex t-items-center t-space-x-2">
                  <div className="t-h-3 t-w-3 t-bg-green-300 t-rounded-full" />
                  <h6 className="t-font-semibold">Pledges</h6>
                </div>
                <h6 className="t-font-semibold">{`$ ${fundraiserDetail?.status?.Pledges}`}</h6>
              </Row>

              <Row className="t-w-full t-flex t-justify-between t-items-center t-my-2">
                <div className="t-flex t-items-center t-space-x-2">
                  <div className="t-h-3 t-w-3 t-bg-green-300 t-rounded-full" />
                  <h6 className="t-font-semibold">Credit</h6>
                </div>
                <h6 className="t-font-semibold">{`$ ${fundraiserDetail?.status?.Credit}`}</h6>
              </Row>
              <Row className="t-w-full t-flex t-justify-between t-items-center t-my-2">
                <div className="t-flex t-items-center t-space-x-2">
                  <div className="t-h-3 t-w-3 t-bg-green-300 t-rounded-full" />
                  <h6 className="t-font-semibold">Cash</h6>
                </div>
                <h6 className="t-font-semibold">{`$ ${fundraiserDetail?.status?.Cash}`}</h6>
              </Row>
              {/* <Row className="t-w-full t-flex t-justify-between t-items-center t-my-2">
                <div className="t-flex t-items-center t-space-x-2">
                  <div className="t-h-3 t-w-3 t-bg-green-300 t-rounded-full" />
                  <h6 className="t-font-semibold">Checks</h6>
                </div>
                <h6 className="t-font-semibold">{`$ ${fundraiserDetail?.status?.Checks}`}</h6>
              </Row> */}

              <Row className="t-w-full t-flex t-justify-between t-items-center t-my-2 ">
                <div className="t-flex t-items-center t-space-x-2">
                  <div className="t-h-3 t-w-3 t-bg-red-300 t-rounded-full" />
                  <h6 className="t-font-semibold">Declined</h6>
                </div>
                <h6 className="t-font-semibold">
                  {`$ ${fundraiserDetail?.status?.Rejected}`}
                </h6>
              </Row>

              {fundraiserDetail?.auctionStatus?.total &&
                fundraiserDetail?.auctionStatus?.total > 0 && (
                  <>
                    <Row className="t-w-full t-flex t-justify-between t-items-center t-my-2 ">
                      <div className="t-flex t-items-center t-space-x-2">
                        <div className="t-h-3 t-w-3 t-bg-red-600 t-rounded-full" />
                        <h6 className="t-text-red-600 t-font-semibold">
                          Items Remaining
                        </h6>
                      </div>
                      <h6 className="t-text-red-600">
                        {fundraiserDetail?.auctionStatus?.remaining || 0}
                      </h6>
                    </Row>
                    <Row className="t-w-full t-flex t-justify-between t-items-center t-my-2">
                      <div className="t-flex t-items-center t-space-x-2">
                        <div className="t-h-3 t-w-3 t-bg-green-300 t-rounded-full" />
                        <h6 className="t-font-semibold">Items Sold</h6>
                      </div>
                      <h6>{fundraiserDetail?.auctionStatus?.sold || 0}</h6>
                    </Row>
                  </>
                )}
            </Col>
            <Divider />
            <Row className="t-flex t-justify-center t-items-center">
              <QRCode
                size={200}
                bgColor={config.colors.tertiary}
                fgColor="#000000"
                value={getWebAppFundraiserUrl(
                  fundraiser?.iD,
                  fundraiser?.organization?.domain,
                )}
              />
            </Row>
            <Row className="t-flex t-justify-center t-items-center t-font-bold text-2xl t-my-2">
              Donation QR code
              <Divider />
            </Row>
            <Row className="t-flex t-justify-center t-items-center">
              <QRCode
                size={200}
                bgColor={config.colors.tertiary}
                fgColor="#000000"
                value={window.location.href.replace(
                  'control-screen',
                  'join-fundraiser',
                )}
              />
            </Row>
            <Row className="t-flex t-justify-center t-items-center t-font-bold text-2xl t-my-2">
              Join Fundraiser QR code
            </Row>
            <Row className="t-flex t-justify-center t-items-center t-my-6">
              <Button
                size="small"
                className="t-w-full t-bg-white"
                onClick={() => setShareModal(true)}
              >
                Share Fundraiser
              </Button>
            </Row>
          </Col>
        </Row>
      </Skeleton>
      {/* <div className="t-flex md:t-hidden t-fixed t-h-16 t-w-full t-px-6 t-py-1 t-bg-primary-100 t-shadow-2xl t-left-0 t-bottom-0 t-justify-between t-items-center t-space-x-4 t-rounded-t-md">
        <Button
          size="small"
          className="t-w-full t-bg-white"
          onClick={() => setShareModal(true)}
        >
          Share Event
        </Button>
      </div> */}
      <ShareModal
        // url={`${window.location.origin}/donation/${fundraiser?.Id}`}
        url={`${awsconfig.API.endpoints[0].api_url}/html/share-fundraiser/${fundraiser?.iD}`}
        visible={shareModal}
        onClose={() => setShareModal(false)}
      />
      <SendRemindersModal
        id={id}
        visible={reminderModal}
        onClose={() => setReminderModal(false)}
      />
    </Card>
  )
}

export { FundraiserControl }
