import React, { useState } from 'react'
import {
  Upload,
  Table,
  Button,
  Card,
  Row,
  Col,
  Select,
  Modal,
  Alert,
  message,
} from 'antd'
import Papa from 'papaparse'
import { useParams } from 'react-router-dom'
import { useAuthSelector } from 'store/authSlice/authReducer'
import Api from 'api/apiv2'
import { useFundraiserSelector } from 'store/fundraiserSlice/fundraiserReducer'

const BulkImportDonations = () => {
  const { id } = useParams()
  const { organization } = useAuthSelector()
  const { fundraiser } = useFundraiserSelector()

  const [parsedData, setParsedData] = useState([])
  const [loading, setLoading] = useState(false)
  const [uploadLoading, setUploadLoading] = useState(false)
  const [csvHeaders, setCsvHeaders] = useState([])
  const [hasHeaders, setHasHeaders] = useState(null)
  const [columnMapping, setColumnMapping] = useState({})
  const [missingColumnsError, setMissingColumnsError] = useState('')
  const [showStatisticModal, setShowStatisticModal] = useState(false)
  const [uploadStatisticData, setUploadStatisticData] = useState()
  const [downloadLoading, setDownloadLoading] = useState(false)
  const [uploadErrorData, setUploadErrorData] = useState([])

  // All possible columns available for mapping
  const allColumns = [
    'FirstName',
    'LastName',
    'PhoneNumber',
    'Email',
    'DonationDate',
    'DonatedAmount',
    'PaymentMethod',
    'OrgDonation',
  ]

  // Columns that are mandatory in the CSV
  const requiredColumns = [
    'PhoneNumber',
    'Email',
    'DonationDate',
    'DonatedAmount',
  ]

  // Handle CSV File Upload
  const handleFileUpload = ({ file }) => {
    setLoading(true)
    const reader = new FileReader()

    reader.onload = (event) => {
      const csvText = event.target.result
      Papa.parse(csvText, {
        header: false,
        skipEmptyLines: true,
        complete: (result) => {
          const csvColumns = result.data[0]
          const data = result.data.slice(1)

          Modal.confirm({
            title: 'Does your file have headers?',
            content: 'Please confirm if the first row contains column headers.',
            okText: 'Yes',
            cancelText: 'No',
            onOk: () => {
              setHasHeaders(true)
              validateAndSetData(data, csvColumns)
            },
            onCancel: () => {
              setHasHeaders(false)
              validateAndSetData(data, csvColumns)
            },
          })
        },
        error: (error) => {
          setLoading(false)
          setMissingColumnsError(`Error parsing CSV file: ${error.message}`)
        },
      })
    }

    reader.readAsText(file)
  }

  const validateAndSetData = (data, csvColumns) => {
    const missingColumns = requiredColumns.filter(
      (col) => !csvColumns.includes(col),
    )
    if (missingColumns.length > 0) {
      setMissingColumnsError(
        `The following required columns are missing from CSV: ${missingColumns.join(
          ', ',
        )}`,
      )
      setLoading(false)
      return
    }

    setMissingColumnsError('')

    // Reset column mapping when new file is uploaded
    setColumnMapping({})

    const validColumns = csvColumns.filter((col) => allColumns.includes(col))

    const formattedData = data.map((row, index) => {
      const formattedRow = {}
      validColumns.forEach((col, i) => {
        formattedRow[col] = row[i] || ''
      })
      return { ...formattedRow, key: index }
    })

    setParsedData(formattedData)
    setCsvHeaders(validColumns)
    setLoading(false)
    message.success('File uploaded successfully!', { duration: 3 })
  }

  // Handle column mapping change
  const handleColumnChange = (value, index) => {
    setColumnMapping((prevMapping) => ({
      ...prevMapping,
      [index]: value,
    }))
  }

  // Check if all columns have been mapped (only when CSV has no headers)
  // eslint-disable-next-line
  const areAllColumnsMapped = () => {
    return csvHeaders.length === Object.keys(columnMapping).length
  }

  // Dynamically create table columns
  const tableColumns = hasHeaders
    ? csvHeaders.map((header, index) => ({
        title: header,
        dataIndex: header,
        key: index,
      }))
    : csvHeaders.map((header, index) => ({
        title: (
          <Select
            style={{ width: '100%' }}
            placeholder="Select Column"
            value={columnMapping[index] || undefined} // Ensures it resets when the file is re-uploaded
            onChange={(value) => handleColumnChange(value, index)}
          >
            {allColumns.map((option) => (
              <Select.Option key={option} value={option}>
                {option}
              </Select.Option>
            ))}
          </Select>
        ),
        dataIndex: header,
        key: index,
      }))

  const tableData = parsedData.map((row, index) => {
    const staticRow = { key: index }
    csvHeaders.forEach((header) => {
      staticRow[header] = row[header]
    })
    return staticRow
  })

  const handleBulkTransactionsUpload = async () => {
    if (organization && fundraiser) {
      const organizationData = {
        OrganizationId: organization.id,
        OrganizationName: organization.Name,
        OrganizationEmail: organization.Email,
      }

      const fundraiserData = {
        FundraiserId: fundraiser.iD,
        FundraiserTitle: fundraiser.title,
      }

      let donationsData = []

      // Case 1: CSV has headers
      if (hasHeaders) {
        donationsData = parsedData.map((row) => {
          const formattedRow = {}
          csvHeaders.forEach((header) => {
            formattedRow[header] = row[header] || ''
          })
          return formattedRow
        })
      }
      // Case 2: CSV does not have headers
      else {
        // eslint-disable-next-line
        if (areAllColumnsMapped()) {
          setMissingColumnsError('')
          donationsData = parsedData.map((row) => {
            const formattedRow = {}
            csvHeaders.forEach((header, index) => {
              const mappedColumn = columnMapping[index]
              if (mappedColumn) {
                formattedRow[mappedColumn] = row[header] || ''
              }
            })
            return formattedRow
          })
        } else {
          setMissingColumnsError('Please map all columns before uploading.')
          return
        }
      }

      const payload = {
        donationsData,
        organizationData,
        fundraiserData,
      }

      try {
        setUploadLoading(true)
        const result = await Api.post(
          `admin/fundraisers/${id}/bulk-donation`,
          payload,
        )

        const response = result.data

        message.success(response.message)

        setUploadStatisticData({
          numberOfDonationsAdded: response.donations.numberOfDonationsAdded,
          numberOfNewConstituent: response.donations.numberOfNewConstituent,
          numberOfSkippedConstituent:
            response.donations.numberOfSkippedConstituent,
          errorInRecords: response.donations.errorInRecords,
          errorData: response.donations.errorData,
        })
        setUploadErrorData(response.donations.errorData)
        setShowStatisticModal(true)
      } catch (error) {
        message.error(
          error?.response?.data?.error?.message ||
            'Bulk Import Donation File cannot be processed due to an error',
          {
            duration: 3,
          },
        )
        setUploadLoading(false)
      }
    } else {
      console.error('Could not get Organization OR fundraiser data')
    }
  }

  const downloadBulkImportErrorFile = () => {
    setDownloadLoading(true)

    const formattedData = uploadErrorData.map((item) => ({
      FirstName: item.FirstName || '',
      LastName: item.LastName || '',
      PhoneNumber: item.PhoneNumber || '',
      Email: item.Email || '',
      DonationDate: item.DonationDate || '',
      DonatedAmount: item.DonatedAmount || '',
      PaymentMethod: item.PaymentMethod || '',
      OrgDonation: item.OrgDonation || '',
    }))

    // Convert the data to CSV using PapaParse
    const csv = Papa.unparse(formattedData)

    // Create a Blob with CSV data
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })

    // Create a link to trigger download
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = 'BulkTransactionUpload_error_data.csv' // Name of the CSV file
    link.click()

    // Reset loading state after download
    setDownloadLoading(false)
  }

  return (
    <Card className="t-w-full">
      <Row justify="end" gutter={[24, 0]}>
        <Col>
          <div className="t-w-full t-mb-4">
            <Upload
              customRequest={handleFileUpload}
              maxCount={1}
              showUploadList={false}
              accept=".csv"
            >
              <Button
                loading={loading}
                className="t-w-full t-mt-2"
                type="primary"
              >
                Upload File
              </Button>
            </Upload>
          </div>
        </Col>

        <Col>
          {parsedData.length > 0 && (
            <Row justify="end" gutter={[24, 0]}>
              <Col>
                <div className="t-w-full t-mb-4">
                  <Button
                    className="t-w-full t-mt-2"
                    type="primary"
                    loading={uploadLoading}
                    onClick={handleBulkTransactionsUpload}
                  >
                    Import
                  </Button>
                </div>
              </Col>
            </Row>
          )}
        </Col>
      </Row>

      {missingColumnsError && (
        <Alert
          message="Error"
          description={missingColumnsError}
          type="error"
          showIcon
          style={{ marginBottom: 16 }}
          closable
        />
      )}

      <div>
        <Table
          columns={tableColumns}
          dataSource={tableData}
          bordered
          pagination={{ pageSize: 5 }}
          scroll={parsedData.length > 0 ? { x: true } : undefined}
        />
      </div>

      <Modal
        centered
        title="Bulk Import Donation Upload Statistics"
        open={showStatisticModal}
        onCancel={() => {
          setShowStatisticModal(false)
          setUploadLoading(false)
        }}
        footer={null}
        destroyOnClose
      >
        {uploadStatisticData && (
          <div>
            {/* Displaying statistics as Alerts */}
            {[
              {
                name: 'Donations added',
                value: 'numberOfDonationsAdded',
                type: 'success', // Success alert
              },
              {
                name: 'Constituents added',
                value: 'numberOfNewConstituent',
                type: 'success', // Success alert
              },
              {
                name: 'Constituents skipped (because they already exist)',
                value: 'numberOfSkippedConstituent',
                type: 'warning', // Warning alert
              },
              {
                name: 'Records not imported due to errors',
                value: 'errorInRecords',
                type: 'error', // Error alert
              },
            ].map((field) => (
              <Alert
                key={field.value}
                message={field.name}
                description={`Count: ${uploadStatisticData[field.value]}`}
                type={field.type}
                className="t-mb-4"
                showIcon
              />
            ))}
            {uploadStatisticData.errorInRecords > 0 && (
              <div className="t-mb-8 t-flex t-items-center t-justify-end">
                <Button
                  className="t-mt-2"
                  type="danger"
                  loading={downloadLoading}
                  onClick={downloadBulkImportErrorFile}
                >
                  Download Error Records File
                </Button>
              </div>
            )}
          </div>
        )}
      </Modal>
    </Card>
  )
}

export { BulkImportDonations }
